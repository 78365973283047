i {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin: 0px;
    padding: 0px;
}
i svg {
    fill:var(--black);
    stroke:var(--black);
    max-width:100%;
}
i.light svg {
    fill:var(--white);
    stroke:var(--white);
}
i.theme svg {
    fill:var(--theme);
    stroke:var(--theme);
}
i.text {
    vertical-align:text-bottom;
}
i.link-footer {
    position: relative;
    bottom: 1px;
}
i.xxxs {
    height:0.75rem;
    width:0.75rem;
}
i.xxs {
    height:0.875rem;
    width:0.875rem;
}
i.xs {
    height:1.375rem;
    width:1.375rem;
}
i.sm {
    height:1.5rem;
    width:1.5rem;
}
i.md {
    width:2rem;
    height:2rem;
}
i.lg {
    width:3rem;
    height:3rem;
}
i.xl {
    width:4rem;
    height:4rem;
}
i.xxl {
    width:6rem;
    height:6rem;
}
i.s1 svg {
    stroke-width: 1px;
}

i.s2 svg {
    stroke-width: 2px;
}

i.s3 svg {
    stroke-width: 3px;
}
@media(max-width:991px) {
    i.lg {
        width:1.5rem;
        height:1.5rem;
    }
}
