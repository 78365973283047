.sig-container canvas{
    border:1px solid var(--med-grey);
}
#waiver-print div,#waiver-print p,#waiver-print, #clone div,#clone p,#clone {
    width:595.28px;
    font-family:'Arial';
    font-size:12px;

}
td {
    padding:1rem 0.3rem !important;
    cursor:pointer;
}
tr.alert-warning {
    background-color: #fff3cd !important;
}
iframe.previewEmail {
    width:800px !important;
    border:2px solid #000;
    min-height:600px;
}
.field-container {
    position:relative;
}
.field-select svg {
    width:32px;
    cursor:pointer;
}
.field-list {
    display:none;
    position:absolute;
    top:50px;
    right: 0;
    background: #FFF;
    padding: 12px;
    border: 1px solid #000;
    z-index: 100;
}
.field-list-show {
    display:block;
}
/* iframe {
visibility: hidden;
} */
.red-alert { background-color: #f8d7da !important; }
.yellow-alert { background-color: #fff3cd !important; }
.orange-alert { background-color: #FFD580 !important; }
.purple-alert { background-color: #E6E6FA !important; }
.blue-alert { background-color: #cce5ff !important; }
.green-alert { background-color: #d4edda !important; }